/*  
Colour Palette
https://coolors.co/031927-427aa1-828489-fcf7f8-c8ad55
#828489 Gray Web
#FCF7F8 Snow
#C8AD55 Vegas Gold
*/

/* CSS Reset */
/* -------------------------------------------------------- */

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* Global Styles */
/* -------------------------------------------------------- */

*,
*:before,
*:after {
  box-sizing: inherit;
}
.container {
  display: grid;
  height: 100vh;
  grid-template-rows: 5vh 1fr;
  grid-template-areas:
    "header"
    "content";
  grid-gap: 1vh;
  background: #fcf7f8 /* Snow */;
  font-family: "Roboto Condensed", sans-serif;
}
a {
  text-decoration: none;
  color: #828489; /* Gray Web */
}
a:hover {
  color: #c8ad55 /* Vegas Gold */;
}
h1 {
  margin-bottom: 5px;
}
p {
  margin-bottom: 15px;
}
h1 {
  font-size: 32px;
}
h2 {
  margin-bottom: 5px;
}
body {
  overflow: hidden;
}
input {
  color: #828489 /* Gray Web */;
}
button {
  margin: 0 10px;
  padding: 0px 10px;
  background-color: #c8ad55; /*Vegas Gold*/
  color: #fcf7f8; /* Snow */
  border-radius: 5px;
}

/* Header Styles */
/* -------------------------------------------------------- */

.header {
  grid-area: "header";
  display: grid;
  grid-template-columns: 330px 50px 1fr;
  grid-template-areas:
    "site-name"
    "logo"
    "site-menu";
  color: #fff /*Snow*/;
  background-color: #111;
  font-size: 2.5rem;
  white-space: nowrap;
}
.site-name {
  grid-area: "site-name";
  height: 100%;
  color: #fff;
  padding-left: 0.5rem;
}
.logo img {
  grid-area: "logo";
  height: 30px;
  width: 30px;
}
.site-menu {
  grid-area: "site-menu";
  height: 100vh;
}
.site-menu a {
  color: inherit;
  font-size: 60%;
  padding-right: 15px;
}
.site-menu a:hover {
  color: #c8ad55 /* Vegas Gold */;
}

/* Content Styles */
/* -------------------------------------------------------- */

.content {
  padding: 15px;
  color: #828489 /* Gray Web */;
  overflow: auto;
}

/* Meal Planner Styles */
/* -------------------------------------------------------- */

.menu {
  display: grid;
  grid-template-areas:
    "menu-header"
    "meals";
  grid-area: menu;
}
.menu-container {
  display: grid;
  grid-template-columns: 320px 1.5fr;
  grid-template-rows: 350px 1fr;
  grid-template-areas:
    "menu weekly-plan"
    "menu mp-display";
}
.menu-container img {
  width: 80%;
  aspect-ratio: 1 / 1;
  padding: 10px;
}
.menu-items {
  height: 80vh;
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 200px);
  overflow-y: auto;
}
.menu-card h2,
.menu-card p {
  padding: 0px 10px;
}
.card-title {
  margin-left: 12px;
}
.weekly-plan {
  padding-top: 15px;
  display: grid;
  grid-template-columns: repeat(7, 150px);
  grid-template-rows: 50px;
  grid-area: weekly-plan;
}
.dayOfWeek {
  height: 25px;
  margin: auto;
  width: 50%;
}
.weekly-plan .board {
  border: 1px solid #828489; /* Gray Web */
  max-width: 150px;
  height: 200px;
}
.mp-display-area {
  grid-area: mp-display;
}
.projects-nav {
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.show-recipe {
  text-align: center;
  position: fixed;
  margin: 0 40%;
  border: 1px solid black;
  box-shadow: 5px 10px #828489 /*Gray Web*/;
  background-color: #fcf7f8; /* Snow */
  color: #828489 /*Gray Web*/;
  height: 300px;
  width: 300px;
}
.close-recipe-btn {
  position: absolute;
  bottom: 10px;
  left: 80px;
}

/* Gain Calculator Styles */
/* -------------------------------------------------------- */

.gc-container {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-row-gap: 30px;
}
#gc-instructions {
  padding-bottom: 30px;
}
#gc-table {
  display: grid;
  grid-template-columns: 100% 100%;
  grid-column-gap: 70px;
}
#gc-table td {
  width: 180px;
  overflow: hidden;
  padding: 2px;
}
